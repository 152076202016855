"use strict";
exports.__esModule = true;
var wk = function (c, msg, transfer, cb) {
    var u = URL.createObjectURL(new Blob([c], { type: 'text/javascript' }));
    var w = new Worker(u);
    w.postMessage(msg, transfer);
    w.addEventListener('message', function (ev) { return cb(null, ev.data); });
    w.addEventListener('error', function (ev) { return cb(ev.error, null); });
    return w;
};
wk.t = [ArrayBuffer, MessagePort];
if (typeof ImageBitmap != 'undefined')
    wk.t.push(ImageBitmap);
if (typeof OffscreenCanvas != 'undefined')
    wk.t.push(OffscreenCanvas);
exports["default"] = wk;
